// Copyright 2018 Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: Apache-2.0

import React from 'react'

// services
import { getApi } from 'services/api-catalog'

// components
import { RedocStandalone } from 'redoc'
import { Container, Header, Icon } from 'semantic-ui-react'

// state
import { observer } from 'mobx-react'
import { isAuthenticated } from 'services/self'
// import * as YAML from 'yamljs'

export default observer(class ApisPage extends React.Component {
  state = {
    spec: null
  }

  async componentDidMount () {
    if (!this.state.spec) {
      this.setState({ spec: process.env.REACT_APP_OPEN_API_DOC_PATH })
    }
    await getApi(this.props.match.params.apiId || 'ANY', true, this.props.match.params.stage, true)
  }

  render () {
    let errorHeader
    let errorBody

    if (!isAuthenticated()) {
      errorHeader = 'Please sign in'
      errorBody = 'You have to be signed in to see an API'
    }

    return (
      <>
        <div>
          {errorHeader && errorBody && (
            <>
              <Header as='h2' icon textAlign='center' style={{ padding: '40px 0px' }}>
                <Icon name='warning sign' circular />
                <Header.Content>{errorHeader}</Header.Content>
              </Header>
              <Container text textAlign='center'>
                <p>{errorBody}</p>
              </Container>
            </>
          )}
        </div>

        {isAuthenticated() && this.state.spec &&
          <RedocStandalone
            spec={this.state.spec}
            options={{
              theme: {
                colors: {
                  primary: {
                    main: '#fa8220'
                  }
                },
                rightPanel: {
                  backgroundColor: 'rgb(40, 45, 68)'
                },
                sidebar: {
                  backgroundColor: 'rgb(245, 247, 248)'
                }
              }
            }}
          />
        }
      </>
    )
  }
})
