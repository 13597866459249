// Copyright 2018 Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: Apache-2.0

import React from 'react'
import { Menu, Image, MenuItem, Button } from 'semantic-ui-react'

import {
  isAdmin,
  isAuthenticated,
  isRegistered,
  logout,
  getLoginRedirectUrl
} from 'services/self'

import { withRouter } from 'react-router-dom'

import { cognitoDomain, cognitoClientId } from '../services/api'

// mobx
import { observer } from 'mobx-react'

import { store } from 'services/state.js'

// components
import MenuLink from 'components/MenuLink'
import { subscribe, unsubscribe } from '../services/api-catalog'

function getCognitoUrl (type) {
  const redirectUri = getLoginRedirectUrl()
  return `${cognitoDomain}/${type}?response_type=token&client_id=${cognitoClientId}&redirect_uri=${redirectUri}`
}

const SubscriptionButtons = observer(class SubscriptionButtons extends React.Component {
  constructor (props) {
    super(props)
    this.state = { pendingAction: null }
  }

  render () {
    const { api } = store
    if (this.state.pendingAction) {
      return <Button style={{ width: '150px' }} loading={true}>this.state.pendingActions</Button>
    }

    if (!api || !isAuthenticated()) {
      const apiIsSubscribable = !!(api && api.apiStage && api.usagePlan)
      if (!apiIsSubscribable) {
        return <Button style={{ width: '150px' }} loading={true}/>
      }
    }

    return (
      api.subscribed ? (
        <Button style={{ width: '150px' }} color="orange" onClick={async () => {
          this.setState({ pendingAction: 'Disabling' })
          await unsubscribe(api.usagePlan.id)
          this.setState({ pendingAction: null })
        }}>API Enabled</Button>
      ) : (
        <Button style={{ width: '150px' }} onClick={async () => {
          this.setState({ pendingAction: 'Enabling' })
          await subscribe(api.usagePlan.id)
          this.setState({ pendingAction: null })
        }}>Enable API</Button>
      )
    )
  }
})

export const NavBar = withRouter(observer(
  class NavBar extends React.Component {
    render () {
      const pathname = window.location.pathname

      const email = store.user && store.user.email
      return <Menu secondary attached style={{ flex: '0 0 auto', borderBottom: '2px solid #fa8220' }} stackable>
        <MenuLink to='/'>
          <Image size='mini' src='/custom-content/nav-logo.png' style={{ paddingRight: '15px' }} />
          {process.env.REACT_APP_API_NAME}
        </MenuLink>

        <Menu.Menu position='right'>
          {isAuthenticated() ? <>
            {isRegistered() && <MenuItem><SubscriptionButtons/></MenuItem>}
            {isAdmin() && <MenuLink active={pathname.includes('/admin/')} to='/admin/apis'>Admin Panel</MenuLink>}
            {isRegistered() && <MenuLink active={pathname === '/dashboard'} to='/dashboard'>My Dashboard</MenuLink>}
            <MenuLink onClick={logout}>
              <div style={{ display: 'flex', flexDirection: 'column', textAlign: 'center' }}>
                {email && <span style={{ marginBottom: '.5rem' }}>
                  {email}
                </span>}
                <span>
                  Sign out
                </span>
              </div>
            </MenuLink>
          </> : <>
            <MenuLink to={getCognitoUrl('login')}>Sign In</MenuLink>
            { process.env.REACT_APP_SIGN_UP_ALLOWED === 'true' &&
              <MenuLink to={getCognitoUrl('signup')}>Register</MenuLink>
            }
          </>}
        </Menu.Menu>
      </Menu>
    }
  }
))

export default NavBar
